import React, { useState, useContext, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Drawer, List, ListItem, ListItemText, Divider, Button, MenuItem, Menu, Slider } from "@material-ui/core";

import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import FolderSharedRoundedIcon from '@material-ui/icons/FolderSharedRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import LaunchIcon from '@material-ui/icons/Launch';

import firebase from "../../firebase/firebase";
import GlobalContext from "../../hooks/GlobalContext";

import logo from '../../assets/logo.png';
import personAdd from '../../assets/personAdd.png';
import personWhite from '../../assets/personWhite.png';

import messaginWhite from '../../assets/messaginWhite.png';
import messagingAdd from '../../assets/messagingAdd.png';

import { isEmpty, isNil } from "ramda";

const useStyles = makeStyles({
    root: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        // justifyContent: 'space-between',
        borderBottom: '1px solid rgb(187, 187, 187)',
    },
    list: {
        width: 300,
        // maxWidth: 430,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '2%',
    },
    fullList: {
        width: 'auto',
    },
    boxButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
    },
    button: {
        margin: 5,
        fontSize: 12,
        backgroundColor: '#F6F6F8',
        color: 'black',
    },
    boxBtnLock: {
        width: 30,
        height: 20,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
    },
    btnLock: {
        backgroundColor: 'white',
        height: 16,
        width: 15,
        borderRadius: 2,
    },


    boxButtonPrincipal: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    IconButtonCustom: {
        width: '100%',
        padding: '12px 0',

        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 0,
        padding: '7px 3px',
  
        '& svg': {
          width: '1.1em',
          height: '1.1em',
        }
      },

    buttonP: {
        height: 32,
        backgroundColor: 'white',
        color: '#3A3E4B',
        width: '100%',
        fontWeight: 'bold',
        borderRadius: 8,
        cursor: 'pointer',
        marginRight: 8,

        '&:hover': {
            background: 'linear-gradient(180deg, #AFDC43 0%, #9DCD2A 100%)',
            '& .box .box-text': {
                color: '#fff'
            }
        },

        '& .box': {
            display: 'flex',
            width: '100%',

            '& .box-img': {
                alignItems: 'center',
                display: 'flex',
            },
            '& .box-text': {
                width: '100%',
                fontSize: 10
            }
        }
    },
    buttonM: {
        height: 32,
        backgroundColor: 'white',
        width: '100%',
        color: '#3A3E4B',
        fontWeight: 'bold',
        borderRadius: 8,
        cursor: 'pointer',

        '&:hover': {
            background: 'linear-gradient(180deg, #FB793C 0%, #F06422 100%);',
            '& .box .box-text': {
                color: '#fff'
            }
        },
        '& .box': {
            display: 'flex',
            width: '100%',

            '& .box-img': {
                alignItems: 'center',
                display: 'flex',
            },
            '& .box-text': {
                width: '100%',
                fontSize: 10
            }
        }
    },

});
const Index = () => {
    const { viewHeader, volumeControl, navigation, mainCtx, authCtx, localStore } = useContext(
        GlobalContext
    );

    const { officeId } = mainCtx;
    const { user } = authCtx;
    const classes = useStyles();
    const officesLinkVisible = Boolean(user);
    const licensesLinkVisible = Boolean(officeId);

    const [tempVolume, setTempVolume] = useState(volumeControl.volume);
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeModeLock, setChangeModeLock] = useState('normal');

    // ----------------------------------------------------------
    const [open, setOpen] = useState(false)
    const [applyStyle, setApplyStyle] = useState(false);
    const [visibleBtn, setVisibleBtn] = useState(false);
    // ----------------------------------------------------------
    const [applyStyle2, setApplyStyle2] = useState(false)
    // ----------------------------------------------------------

    useEffect(() => {
        if (!isNil(localStore.data.messageLicense) && !isEmpty(localStore.data.messageLicense)) {
            setVisibleBtn(true)
        } else {
            setVisibleBtn(false)
        }
    }, [localStore.data && localStore.data.messageLicense])

    const toggleDrawer = () => setOpen(!open)
    const cleanLocalStore = () => {
        // remove from localstorage the office id
        localStore.update(
            localStore.storageKeys.selectedOfficeId,
            null
        );
        // remove license id from localstorage
        localStore.update(
            localStore.storageKeys.messageLicense,
            ''
        );

        firebase.auth().signOut();
        mainCtx.setOfficeId(null);
        setAnchorEl(null);
        navigation.toSignIn();
    }

    const toggleVisitUser = () => {
        localStore.update(
            localStore.storageKeys.messageVisitEnabled,
            true
        );
    }

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
        >
            <div className={classes.boxButtons}>
                {/* <Button
                    variant="contained"
                    className={classes.button}
                    style={{ width: 190 }}
                    startIcon={<SettingsIcon style={{ color: '#F26827' }} />}
                >
                    DISPLAY SETTINGS
                </Button> */}
                <Button
                    variant="contained"
                    className={classes.button}
                    fullWidth
                    startIcon={<FolderSharedRoundedIcon style={{ color: '#4984C2' }} />}
                    onClick={() => {
                        window.open("https://portal2.insyncapp.io/");
                        // window.open("https://insync-stg-portal.web.app/");
                    }}
                >
                    PORTAL
                </Button>
                <Button
                    variant="contained"
                    fullWidth
                    className={classes.button}
                    startIcon={<ErrorOutlineRoundedIcon style={{ color: '#A4D334' }} />}
                    onClick={() => {
                        window.open("http://support.insyncapp.io");
                    }}
                >
                    FAQ
                </Button>
            </div>
            {officesLinkVisible && (
                <ListItem
                    button
                    style={{ marginTop: 20 }}
                    onClick={() => {
                        localStore.update(
                            localStore.storageKeys.messageLicense,
                            ''
                        );
                        navigation.toOfficeSelect(mainCtx.officeId);
                        setAnchorEl(null);
                    }}
                >
                    <ListItemText primary='Office List' />
                </ListItem>
            )}
            <Divider />
            {licensesLinkVisible && (
                <ListItem
                    button
                    onClick={() => {
                        localStore.update(
                            localStore.storageKeys.messageLicense,
                            ''
                        );
                        navigation.toLicenseSelect(mainCtx.officeId);
                        setAnchorEl(null);
                    }}
                >
                    <ListItemText primary='Licenses' />
                </ListItem>
            )}
            <Divider />
            {officesLinkVisible && (
                <ListItem
                    button
                    onClick={cleanLocalStore}
                >
                    <ListItemText primary='Sign Out' />
                </ListItem>
            )}
            <Divider />
            {/* <List>
                {data.map((i, index) => (
                <div key={i.id}>
                    <ListItem button >
                        <ListItemText primary={i.name} />
                    </ListItem>
                    <Divider />
                </div>
                ))}
            </List> */}
        </div>
    );

    if (!viewHeader.viewHeader) {
        return null;
    }

    const moveRight = () => setChangeModeLock('right')
    const moveLeft = () => setChangeModeLock('left')
    const moveNormal = () => setChangeModeLock('normal')

    return (
        <div className={classes.root}>
            <Drawer anchor='left' open={open} onClose={toggleDrawer}>

                <div className={classes.list}>
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={toggleDrawer}
                        className={classes.closeButton}
                    >
                        <HighlightOffRoundedIcon />
                    </IconButton>
                    <img src={logo} style={{ height: 59, width: 187, alignSelf: 'center' }} />
                    <IconButton
                        onClick={e => {
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        <VolumeUpRoundedIcon />
                    </IconButton>
                    <Divider />
                    {list()}
                </div>
            </Drawer>
            <div style={{ display: 'flex', width: '100%' }}>
                <IconButton onClick={toggleDrawer}>
                    <MenuRoundedIcon />
                </IconButton>

                {visibleBtn ?
                    <div className={classes.boxButtonPrincipal} style={{ width: '70%' }}>
                        {/* <IconButton className={classes.IconButtonCustom}> */}
                        <Button variant='contained' className={classes.buttonP} onClick={() => { setApplyStyle(false); navigation.toPaging(); }} onMouseLeave={() => setApplyStyle(false)} onMouseEnter={() => setApplyStyle(true)}>
                            <div className="box">
                                <div className="box-img" >
                                    <img src={applyStyle ? personWhite : personAdd} style={{ marginRight: 10 }} />
                                </div>
                                <div className="box-text">PAGING</div>
                            </div>
                        </Button>
                        <Button variant='contained' className={classes.buttonM} onClick={toggleVisitUser} onMouseLeave={() => setApplyStyle2(false)} onMouseEnter={() => setApplyStyle2(true)}>
                            <div className="box">
                                <div className="box-img">
                                    <img src={applyStyle2 ? messaginWhite : messagingAdd} style={{ marginRight: 10 }} />
                                </div>
                                <div className="box-text">MESSAGES</div>
                            </div>
                        </Button>
                        {/* </IconButton> */}
                    </div>
                    : null}
            </div>
            <Menu
                disableScrollLock
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                <MenuItem style={{ paddingTop: 20 }}>
                    <Slider
                        style={{ minHeight: "15vh", }}
                        orientation="vertical"
                        defaultValue={volumeControl.volume}
                        aria-labelledby="volume-slider"
                        valueLabelDisplay="auto"
                        value={tempVolume}
                        onChange={(e, v) => {
                            setTempVolume(v);
                        }}
                        step={1}
                        max={100}
                        min={0}
                        onChangeCommitted={(e, v) => {
                            volumeControl.updateVolume(v);
                            setAnchorEl(null);
                            volumeControl.testVolume();
                        }}
                    />
                </MenuItem>
            </Menu>
            {/* <div>
                {changeModeLock === 'normal' && (    
                    <IconButton onClick={moveRight}>
                        <div className={classes.boxBtnLock} style={{ backgroundColor: changeModeLock === 'normal' ? 'black' : 'rgba(0, 0, 0, 0.5)' }}>
                            <div className={classes.btnLock} style={{ marginLeft: 2 }} />
                        </div>
                    </IconButton>
                )}
                {changeModeLock === 'normal' && (
                    <IconButton onClick={moveLeft}>
                        <div className={classes.boxBtnLock} style={{ backgroundColor: changeModeLock === 'normal' ? 'black' : 'rgba(0, 0, 0, 0.5)', justifyContent: 'flex-end' }}>
                            <div className={classes.btnLock} style={{ marginRight: 2 }} />
                        </div>
                    </IconButton>
                )}
                {changeModeLock !== 'normal' && (
                    <IconButton onClick={moveNormal} style={{ color: 'black' }}>
                        <LaunchIcon />
                    </IconButton>
                )}
                {changeModeLock !== 'normal' && (
                    <IconButton onClick={moveNormal}>
                        <LockRoundedIcon style={{ color: 'black' }}/>
                    </IconButton>
                )}
                <IconButton >
                    <HighlightOffRoundedIcon style={{ color: 'black' }} />
                </IconButton>
            </div> */}
        </div>
    )
}

export default Index;