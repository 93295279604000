import React, { useState, useEffect, useContext, useRef } from "react";
// import getOfficesMeta from "../../../firebase/httpsCallable/getOfficesMeta";
import {
    ListItem,
    ListItemText,
    List,
    CircularProgress,
    CardContent,
    CardActions,
    Button,
    Card,
    CardHeader
} from "@material-ui/core";
import GlobalContext from "../../../hooks/GlobalContext";

export default function(props) {
    const {
        officeList,
        localStore,
        licensesDoc,
        mainCtx,
        navigation,
        authCtx
    } = useContext(GlobalContext);
    const {
        loading,
        error,
        offices,
        initializing,
        setInitializing
    } = officeList;

    useEffect(() => {
        officeList.getOffices();

        localStore.update(
            localStore.storageKeys.messageVisitEnabled,
            false
        );
    }, []);
    

    const matchingOffice = offices.find(office => {
        return office.officeId === localStore.data.selectedOfficeId;
    });

    if (matchingOffice && initializing) {
        officeList.setInitializing(false);
        licensesDoc.setInitializing(true);
        mainCtx.setOfficeId(localStore.data.selectedOfficeId);
        navigation.toLicenseSelect(localStore.data.selectedOfficeId);
    }

    if (loading) {
        return <CircularProgress size={30} />;
    }
    if (error) {
        return (
            <Card>
                <CardHeader title="Error" />
                <CardContent>{error}</CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            officeList.getOffices();
                        }}
                    >
                        Retry
                    </Button>
                </CardActions>
            </Card>
        );
    }

    return (
        <React.Fragment>
            <List>
                {offices
                    .sort((a, b) => {
                        const nameA = a.name;
                        const nameB = b.name;
                        if (nameA > nameB) {
                            return 1;
                        }
                        if (nameA < nameB) {
                            return -1;
                        }
                        return 0;
                    })
                    .map(office => {
                        return (
                            <OfficeListItem office={office} key={office.name}>
                                {office.name}
                            </OfficeListItem>
                        );
                    })}
            </List>
        </React.Fragment>
    );
}

function OfficeListItem(props) {
    const { navigation, mainCtx, localStore, licensesDoc } = useContext(
        GlobalContext
    );
    const office = props.office;
    return (
        <ListItem
            button
            divider
            onClick={() => {
                localStore.update(
                    localStore.storageKeys.selectedOfficeId,
                    office.officeId
                );
                licensesDoc.setInitializing(true);
                mainCtx.setOfficeId(office.officeId);
                navigation.toLicenseSelect(office.officeId);
            }}
        >
            <ListItemText primary={office.name} />
        </ListItem>
    );
}
