import React, { useContext, useState } from "react";
import { isEmpty } from "ramda";

import { ButtonBase, Paper, makeStyles, useMediaQuery, Tooltip, Avatar } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import checkKeep from '../../../../assets/checkKeep.png'

import GlobalContext from "../../../../hooks/GlobalContext";
import MessagingContext from "../../Messaging/MessagingContext";

const useStyles = makeStyles({
    root: {

    },
    personStyle: {
        height: 50,
        border: '2px solid #f86f46',
        color: 'black',
        backgroundColor: 'white',
        margin: 5,
        borderRadius: 10,
        padding: 2,

        '&:hover': {
            border: '3px solid #f86f46',
        },

        '& p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 0,
            padding: 0,
            width: '100%',
            textAlign: 'center'
        }
    }
});

export default function (props) {

    const { user, selectUser } = props;
    const { mainCtx, navigation, localStore } = useContext(GlobalContext);
    const messaging = useContext(MessagingContext)
    const classes = useStyles();
    const [disabledButtom, setDisabledButtom] = useState(false)
    // ----------------------------------------------------------------
    const smScreen = useMediaQuery("(max-width:600px)");
    const mdScreen = useMediaQuery("(max-width:960px)");
    const lgScreen = useMediaQuery('(max-width:1280px)');
    const xlScreen = useMediaQuery("(max-width:1920px)");
    // ----------------------------------------------------------------

    return (
        <ButtonBase
            // style={{ backgroundColor: user.selected ? '#F86F46' : 'white',  color: user.selected ? 'white' : 'black' }}
            onClick={selectUser(user)}
            disabled={disabledButtom}
        >
            <>
            { user.selected ? <div style={{ color: '#fff', position: 'absolute', top: 0, zIndex: 999, left: 25 }}><img src={checkKeep} style={{ }} /></div> : null}
            <Tooltip
                disabled
                disableFocusListener
                key={user.id}
                title={user.name}
            >
                <Avatar
                    style={{
                        backgroundColor: user.bgColor,
                        fontSize: 14,
                        width: 36,
                        height: 36,
                        margin: 4
                    }}
                >
                    {user.name.slice(0, 2)}
                </Avatar>
            </Tooltip>
            </>

        </ButtonBase>
    );
}