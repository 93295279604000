import firebase from "../firebase";

export default function({ 
    bgColor,
    name,
    officeId,
    personId,
    phoneNumber,
    soundId,
    textColor,
    textOnPage,
    updatedAt,
    rooms
 }) {
    const data = { 
        bgColor,
        name,
        officeId,
        personId,
        phoneNumber,
        soundId,
        textColor,
        textOnPage,
        updatedAt,
        rooms
    };

    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "pagingAdmin",
        funcName: "updatePeopleApp",
        data: data
    });
}
