import firebase from "../firebase";

export default function({ officeId }) {
    console.log(officeId);
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "officeAdmin",
        funcName: "getPatientTracking",
        data: { officeId }
    });
}
