import { createContext, useState, useEffect } from "react";
import firebase from "../firebase/firebase";

export const authContext = createContext({
    user: null,
    claims: null,
    initializing: true,
    error: null,
    isSuperAdmin: false,
    authTime: null
});

export default function(mainRouter) {
    const [user, setUser] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const [claims, setClaims] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [authTime, setAuthTime] = useState(null);
    // const [error, setError] = useState(null);

    async function onChange(fbuser) {
        
        if (fbuser) {
            const tokenResult = await firebase
                .auth()
                .currentUser.getIdTokenResult(true);

            setUser(fbuser);
            setClaims(tokenResult.claims);
            setAuthTime(new Date(tokenResult.authTime).valueOf() / 1000);
            if (
                tokenResult.claims &&
                tokenResult.claims.globalRoles &&
                tokenResult.claims.globalRoles.includes("superAdmin")
            ) {
                setIsSuperAdmin(true);
            } else {
                setIsSuperAdmin(false);
            }

            mainRouter.push("/offices");
        } else {
            setUser(null);
            setClaims(null);
            mainRouter.push("/signin");
        }
        setInitializing(false);
    }

    /*eslint-disable*/
    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged(onChange);
        return () => unsub();
    }, []);
    /*eslint-enable*/
    return { user, claims, initializing, isSuperAdmin, authTime };
}
