import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import Input from 'react-phone-number-input/input'
import { isEmpty, isNil, view } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Button, IconButton, Snackbar, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, CircularProgress,
  TextField, Grid, NoSsr, MenuItem, Typography, Paper, Divider,
  FormControlLabel, Checkbox
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';

import { theme, colors } from '../../../../../theme/theme';
import CustomTextField from '../../../../CustomTextField';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        maxHeight: '70%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '15px 24px',
      },
    },
    typeModal: {
      background: '#197FE8',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    boxTextField: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap'
    },
    customTextField: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        borderRadius: 6,
        height: 56,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.info.main,
          borderWidth: 1,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.dark.main,
          // borderColor: '#DDE6EA',
          borderWidth: 1,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
          borderWidth: 1,
        },
        '&.Mui-disabled': {
          background: theme.palette.mute.main,
          color: colors.general.text,
        },

      },
      '& .MuiOutlinedInput-input': {
        fontWeight: 400,
        fontSize: 16,
        '&.Mui-disabled': {
          color: colors.general.text
        }
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#f5f5f5',
      },

      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginTop: 4,
        fontWeight: 500,
        textAlign: 'right',
      },
      '& .MuiFormLabel-root': {
        color: colors.general.text,
        fontWeight: 400,
        fontSize: 16,
        '&.Mui-focused': {
          color: theme.palette.primary.main,
          fontWeight: 500,
        },
        '&.Mui-error': {
          color: theme.palette.error.main,
          fontWeight: 500,
        },
      },
      '&:hover .MuiFormLabel-root': {
        color: theme.palette.dark.main
      },
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      justifyContent: 'space-between',
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      }
    },
    scrollCustom: {

      background: '#fff',
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
        backgroundColor: '#fff',
        width: 12,     /* Tamaño del scroll en vertical */
        height: 12,    /* Tamaño del scroll en horizontal */
        // display: 'none',  /* Ocultar scroll */
      },

      /* background of the scrollbar except button or resizer */
      '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

      '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

      /* scrollbar itself */
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: 16,
        border: '4px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '4px solid #f4f4f4'
      },

      /* set button(top and bottom of the scrollbar) */
      '&::-webkit-scrollbar-button': { display: 'none' },
    },

    boxCustomSchedule: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    boxOccurr: {
      // backgroundColor: 'red',
      width: '100%',
      // height: 50,
      '& p': {
        padding: 0,
        marginTop: -3,
        position: 'absolute',
        fontSize: 12,
      }
    },
    selected: {
      padding: 0,
      margin: 0,
      // marginTop: 15,
      width: '100%',
      height: 40,
      backgroundColor: "#f5f5f5",

    },
    textSelect: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    inputCustomTime: {
      display: 'flex',
      // marginTop: '8px',
      // marginBottom: '14px',
      // marginRight: '8px',

      '& .MuiFormLabel-root': {
        fontSize: '14px',
        top: '-4px',
        fontWeight: 'bold',
        color: '#000'
      },
      '& .MuiFormLabel-filled': {
        top: '0px',
      },
      '& .MuiInputBase-input': {
        padding: '14px',
        fontSize: ' 14px',
        backgroundColor: '#f5f5f5'
      },
    },

    text: {
      margin: 0,
      padding: 0,
      color: '#000',
      textAlign: 'left',
      fontSize: 12,
    },
    boxInputBox: {

      display: 'flex',
      alignItems: 'center',
      border: '1px solid #c4c4c4',
      borderRadius: 3,
      background: '#f5f5f5',
      padding: '0 10px',
      height: 39,

      '&:hover': {
        borderColor: '#0276fd',
        borderWidth: 2,
        padding: '0 9px',
      },
    },
    boxInputPhone: {
      font: 'inherit',
      color: '#030303cf',
      width: '100%',
      border: '1px solid transparent',
      margin: '0',
      display: 'block',
      padding: '5.5px',
      background: '#f5f5f5',
      fontSize: 16,

      '&:focus-visible': {
        outline: 'none'
      }
    },

  })
);

export default function DialogVisit(props) {

  const { visible, toggle, handleSave, loading, setLoading, form, formError, setForm, onChangeText, handleChangeDate, timestampNow, invalidDate, handleFavorite,
    onChangeTextSms, validateEmail
  } = props;
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // ----------------------------------------------------------
  useEffect(() => {
    if (visible) {
      document.body.style = "padding:0; overflow-y: hidden;";
      setLoading(false)
    } else {
      document.body.style = "";
    }
  }, [visible])
  // ----------------------------------------------------------

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>UPDATE
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)} className={clsx(classes.scrollCustom, '')}>
        {/* <DialogContentText>Let Google help apps determine location.</DialogContentText> */}

        <Grid className={clsx(classes.boxTextField, '')} container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              // label="Name"
              // placeholder="Name"
              labelText="Name"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              mt={0}
              mb={0}
              mr={5}
              textColor="#030303"
              name="patientName"
              id="name"
              value={form.patientName}
              error={formError.patientName}
              helperText="required"
              // required={true}
              onChangeText={onChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              // label="Name"
              // placeholder="Name"
              labelText="Last Name"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              mt={0}
              mb={0}
              mr={5}
              textColor="#030303"
              name="lastName"
              id="lastName"
              value={form.lastName}
              error={formError.lastName}
              helperText="required"
              // required={true}
              onChangeText={onChangeText}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <p className={classes.text}>Arrival Time</p>
            <TextField
              className={classes.inputCustomTime}
              type="time"
              value={moment(form.arrivalTime.toString(), 'MM-DD-YYYY hh:mm a').format('HH:mm')}
              onChange={handleChangeDate('arrivalTime')}
              variant="outlined"
              fullWidth
              autoComplete='off'
              error={formError.arrivalTime || invalidDate}
            // defaultValue="07:30"
            // id="time"
            // label="Alarm clock"
            />
            {formError.arrivalTime ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : invalidDate ?
              <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>Future time invalid</div>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className={classes.text}>Appointment Time</p>
            <TextField
              className={classes.inputCustomTime}
              type="time"
              value={moment(form.appointmentTime.toString(), 'MM-DD-YYYY hh:mm a').format('HH:mm')}
              onChange={handleChangeDate('appointmentTime')}
              variant="outlined"
              fullWidth
              autoComplete='off'
              error={formError.appointmentTime}
            // defaultValue="07:30"
            // id="time"
            // label="Alarm clock"
            />
            {formError.appointmentTime ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              labelText="Appointment Length (Minutes)"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              id="appointment-length picker"
              mt={0}
              mb={0}
              mr={5}
              textColor="#030303"
              type="number"
              name="appointmentLength"
              value={`${form.appointmentLength}`}
              error={formError.appointmentLength}
              helperText="required"
              // required={true}
              onChangeText={onChangeText}
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.text}>Due Out Time</p>
            <TextField
              className={classes.inputCustomTime}
              type="time"
              value={moment(moment(form.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf() + form.appointmentLength * 60000).format('HH:mm')}
              onChange={e => {
                const time = e ? moment(`${timestampNow} ${e.target.value}`, 'MM-DD-YYYY hh:mm a').valueOf() : Date.now();
                setForm({ ...form, appointmentLength: Math.floor((time - moment(form.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf()) / 60000) })
              }}
              variant="outlined"
              fullWidth
              autoComplete='off'
              error={formError.appointmentLength}
            // defaultValue="07:30"
            // id="time"
            // label="Alarm clock"
            />
            {formError.appointmentLength ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
          </Grid>
        </Grid>

        {(form.information) ?
          <>
            <Divider style={{ margin: 15, height: 3 }} />


            <Grid
              container
              spacing={1}
              justify="center"
              style={{ textAlign: "center" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomTextField
                  // label="Name"
                  placeholder="Contact name"
                  labelText="Contact First Name"
                  variant="outlined" // outlined, filled, 
                  className={classes.customTextField}
                  mt={0}
                  mb={0}
                  mr={5}
                  textColor="#000"
                  fontSizeText="15"
                  name="contactFirstName"
                  id="contactFirstName"
                  value={form.contactFirstName}
                  error={formError.contactFirstName}
                  helperText="required"
                  // required={true}
                  onChangeText={onChangeText}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomTextField
                  // label="Name"
                  placeholder="Contact last name"
                  labelText="Contact Last Name"
                  variant="outlined" // outlined, filled, 
                  className={classes.customTextField}
                  mt={0}
                  mb={0}
                  mr={5}
                  textColor="#000"
                  fontSizeText="15"
                  name="contactLastName"
                  id="contactLastName"
                  value={form.contactLastName}
                  error={formError.contactLastName}
                  helperText="required"
                  // required={true}
                  onChangeText={onChangeText}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <p className={classes.text}>Contact Cell Phone</p>
                <div className={classes.boxInputBox}>
                  <span style={{ fontSize: 15, color: '#000' }}>+1</span>
                  <Input
                    country="US"
                    placeholder="(000) 000-0000"
                    value={form.contactPhone}
                    onChange={onChangeTextSms}
                    maxLength={14}
                    className={classes.boxInputPhone}
                    style={{ border: formError.contactPhone ? '1px solid #FF4848' : '' }}
                  />
                   <FormControlLabel
                      value="start"
                      control={<Checkbox icon={<BookmarkBorderIcon />} checkedIcon={<BookmarkOutlinedIcon />} color="primary" checked={form.contactFavorite === 'phone'} onChange={handleFavorite('phone')} />}
                      // label={<Typography style={{ fontWeight: checked ? 700 : 100 }}></Typography>}
                      labelPlacement="end"
                      style={{ margin: 0, height: 20 }}
                  />
                </div>
                {formError.contactPhone ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomTextField
                  // label="Name"
                  placeholder="Contact E-Mail"
                  labelText="Contact Email Address"
                  variant="outlined" // outlined, filled, 
                  className={classes.customTextField}
                  mt={0}
                  mb={0}
                  mr={5}
                  fontSizeText="15"
                  textColor="#000"
                  name="contactEmail"
                  id="contactEmail"
                  value={form.contactEmail}
                  error={formError.contactEmail || validateEmail}
                  helperText={formError.contactEmail ? "required" : validateEmail ? 'Please enter a valid email' : null}
                  // required={true}
                  onChangeText={onChangeText}
                  iconEnd={
                    <FormControlLabel
                        value="start"
                        control={<Checkbox icon={<BookmarkBorderIcon />} checkedIcon={<BookmarkOutlinedIcon />} color="primary" checked={form.contactFavorite === 'email'} onChange={handleFavorite('email')} />}
                        // label={<Typography style={{ fontWeight: checked ? 700 : 100 }}></Typography>}
                        labelPlacement="end"
                        style={{ margin: 0, height: 20 }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </> : null}

      </DialogContent>
      <DialogActions className={classes.btnAction}>
        <Button onClick={toggle}>
          CANCEL
        </Button>
        {!loading && (
          <Button onClick={handleSave} color="primary">
            SAVE
          </Button>
        )}
        {loading && (
          <CircularProgress></CircularProgress>
        )}
      </DialogActions>
    </Dialog>

  );
}

