import useVisits from "./useVisits";
import usePatientTrackingDoc from "./usePatientTrackingDoc";
import PatientTrackingEventHandler from "./PatientTrackingEventHandler";

export default function(props) {
    const { visits, loading } = useVisits();
    const patientTrackingDoc = usePatientTrackingDoc();
    const { rooms, stages, columnLayout, sortData, visitLate } = patientTrackingDoc;
    PatientTrackingEventHandler(visits, loading);

    return { visits, stages, rooms, columnLayout, patientTrackingDoc, sortData, visitLate };
}
