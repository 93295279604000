import React, { useContext } from "react";
import {
    Grid,
    TextField,
    Checkbox,
    MenuItem,
    FormControlLabel
} from "@material-ui/core";

import GlobalContext from "../../hooks/GlobalContext";
export default React.forwardRef((props, ref) => {
    const { localStore } = useContext(GlobalContext);

    return (
        <React.Fragment>
            <MenuItem>
                <TextField
                    label="Persons Columns"
                    type="number"
                    value={localStore.data.personsColumns}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.personsColumns,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
            <MenuItem>
                <TextField
                    label="Persons Height"
                    type="number"
                    value={localStore.data.personsHeight}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.personsHeight,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
            <MenuItem>
                {/* <TextField
                    disabled={!localStore.data.personsContainerEnabled}
                    label="Persons Container Height"
                    type="number"
                    value={localStore.data.personsContainerHeight}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.personsContainerHeight,
                            e.target.value
                        );
                    }}
                /> */}
                <FormControlLabel
                    label="Persons Container Enabled"
                    control={
                        <Checkbox
                            label="Persons Container Enabled"
                            checked={localStore.data.personsContainerEnabled}
                            onChange={e => {
                                localStore.update(
                                    localStore.storageKeys
                                        .personsContainerEnabled,
                                    !localStore.data.personsContainerEnabled
                                );
                            }}
                        ></Checkbox>
                    }
                ></FormControlLabel>
            </MenuItem>
            <MenuItem>
                <FormControlLabel
                    label="Person Colors Always Visible"
                    control={
                        <Checkbox
                            checked={localStore.data.personColorsAlwaysVisible}
                            onChange={() => {
                                localStore.update(
                                    localStore.storageKeys
                                        .personColorsAlwaysVisible,
                                    !localStore.data.personColorsAlwaysVisible
                                );
                            }}
                        ></Checkbox>
                    }
                ></FormControlLabel>
            </MenuItem>
            <MenuItem>
                <TextField
                    label="MeaningButtons Columns"
                    type="number"
                    value={localStore.data.meaningButtonsColumns}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.meaningButtonsColumns,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
            <MenuItem>
                <TextField
                    label="MeaningButtons Height"
                    type="number"
                    value={localStore.data.meaningButtonsHeight}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.meaningButtonsHeight,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
            <MenuItem>
                <TextField
                    label="Rooms Columns"
                    type="number"
                    value={localStore.data.roomsColumns}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.roomsColumns,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
            <MenuItem>
                <TextField
                    label="Rooms Height"
                    type="number"
                    value={localStore.data.roomsHeight}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.roomsHeight,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
            <MenuItem>
                <TextField
                    label="Messages Height"
                    type="number"
                    value={localStore.data.messagesHeight}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.messagesHeight,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
            <MenuItem>
                <TextField
                    label="Patient Tracking Height"
                    type="number"
                    value={localStore.data.patientTrackingHeight}
                    onChange={e => {
                        localStore.update(
                            localStore.storageKeys.patientTrackingHeight,
                            e.target.value
                        );
                    }}
                />
            </MenuItem>
        </React.Fragment>
    );
});
