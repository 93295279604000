import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from "../../../../../hooks/GlobalContext";
import PatientTrackingContext from "../../PatientTrackingContext";
import { VisitContext } from "../../VisitContext";

import TableBody from "../../Table/TableBody";
import { isEmpty, isNil, descend, prop, sortWith, ascend, concat } from "ramda";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    addCustom: {
        // color: '#197FE8',
        minWidth: 0,
        paddingRight: 12,
        textTransform: 'none'
    },
    bubbleCustom: {
        width: 'auto',
        padding: '0px 20px',
        borderRadius: 25,
        fontSize: 11,
        display: 'flex',
        color: '#fff',
        alignContent: 'center',
        textTransform: 'uppercase'
    },
    scrollInner: {

        '&::-webkit-scrollbar': {
            backgroundColor: '#fff',
            width: 16,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#fff'
        },
        '&::-webkit-scrollbar-track:hover': {
            backgroundColor: '#f4f4f4'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#babac0',
            borderRadius: 16,
            border: '5px solid #fff'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a0a0a5',
            border: '4px solid #f4f4f4',
        },
        '&::-webkit-scrollbar-button': { display: 'none' },

        '&::-webkit-scrollbar:horizontal': {
            height: 13
        },
    },
}));

export default function (props) {

    const { localStore } = useContext(GlobalContext);
    const patientTracking = useContext(PatientTrackingContext);
    const { stageId } = props;
    const { visits, stages, sortData, patientTrackingDoc } = patientTracking;

    const classes = useStyles();
    // ----------------------------------------------------------
    // ----------------------------------------------------------
    const filterSelect = !isEmpty(localStore.data.patientTrackingfilter) ? localStore.data.patientTrackingfilter.map(e => e) : [];

    const nuevofilterArray = visits.filter(visit =>
        !isEmpty(filterSelect) ?
            filterSelect.includes(visit.stageHistory[visit.stageHistory.length - 1].stageId)
            :
            visit.stageHistory[visit.stageHistory.length - 1].stageId === stageId
    );
    // ----------------------------------------------------------
    let sortByVisits;
    if (sortData.length > 0) {

        if (!isEmpty(sortData[0].value) && isEmpty(sortData[1].value) && isEmpty(sortData[2].value)) {
            let valText = '';
            switch (sortData[0].value) {
                case 'Room':
                    valText = 'name'
                    break;
                case 'Stage':
                    valText = 'name'
                    break;
                case 'ArrivalTime':
                    valText = 'arrivalTime'
                    break;
                case 'AppointmentTime':
                    valText = 'appointmentTime'
                    break;
                case 'TimeElapsed':
                    if (patientTrackingDoc.visitSettings.countFrom === 'appointmentTime') {
                        valText = 'appointmentTime'
                    } else { valText = 'arrivalTime' }
                    break;
                case 'TimeRemaining':
                    valText = 'dueOutTime'
                    break;
                default:
                    valText = 'name'
                    break;
            }

            sortByVisits = sortWith([
                sortData[0].order === 'DESC' ? descend(prop(`${valText}`)) : ascend(prop(`${valText}`)),
            ], nuevofilterArray);

        } else if (!isEmpty(sortData[0].value) && !isEmpty(sortData[1].value) && isEmpty(sortData[2].value)) {
            let valText = '';
            let valTextLevel = '';
            switch (sortData[0].value) {
                case 'Room':
                    valText = 'name'
                    break;
                case 'Stage':
                    valText = 'name'
                    break;
                case 'ArrivalTime':
                    valText = 'arrivalTime'
                    break;
                case 'AppointmentTime':
                    valText = 'appointmentTime'
                    break;
                case 'TimeElapsed':
                    if (patientTrackingDoc.visitSettings.countFrom === 'appointmentTime') {
                        valText = 'appointmentTime'
                    } else { valText = 'arrivalTime' }
                    break;
                case 'TimeRemaining':
                    valText = 'dueOutTime'
                    break;
                default:
                    valText = 'name'
                    break;
            }
            switch (sortData[1].value) {
                case 'Room':
                    valTextLevel = 'name'
                    break;
                case 'Stage':
                    valTextLevel = 'name'
                    break;
                case 'ArrivalTime':
                    valTextLevel = 'arrivalTime'
                    break;
                case 'AppointmentTime':
                    valTextLevel = 'appointmentTime'
                    break;
                case 'TimeElapsed':
                    if (patientTrackingDoc.visitSettings.countFrom === 'appointmentTime') {
                        valTextLevel = 'appointmentTime'
                    } else { valTextLevel = 'arrivalTime' }
                    break;
                case 'TimeRemaining':
                    valTextLevel = 'dueOutTime'
                    break;
                default:
                    valTextLevel = 'name'
                    break;
            }

            sortByVisits = sortWith([
                sortData[0].order === 'DESC' ? descend(prop(`${valText}`)) : ascend(prop(`${valText}`)),
                sortData[1].order === 'DESC' ? descend(prop(`${valTextLevel}`)) : ascend(prop(`${valTextLevel}`)),
            ], nuevofilterArray);

        } else {
            let valText = '';
            let valTextLevel = '';
            let valTextLevelBy = '';
            switch (sortData[0].value) {
                case 'Room':
                    valText = 'name'
                    break;
                case 'Stage':
                    valText = 'name'
                    break;
                case 'ArrivalTime':
                    valText = 'arrivalTime'
                    break;
                case 'AppointmentTime':
                    valText = 'appointmentTime'
                    break;
                case 'TimeElapsed':
                    if (patientTrackingDoc.visitSettings.countFrom === 'appointmentTime') {
                        valText = 'appointmentTime'
                    } else { valText = 'arrivalTime' }
                    break;
                case 'TimeRemaining':
                    valText = 'dueOutTime'
                    break;
                default:
                    valText = 'name'
                    break;
            }
            switch (sortData[1].value) {
                case 'Room':
                    valTextLevel = 'name'
                    break;
                case 'Stage':
                    valTextLevel = 'name'
                    break;
                case 'ArrivalTime':
                    valTextLevel = 'arrivalTime'
                    break;
                case 'AppointmentTime':
                    valTextLevel = 'appointmentTime'
                    break;
                case 'TimeElapsed':
                    if (patientTrackingDoc.visitSettings.countFrom === 'appointmentTime') {
                        valTextLevel = 'appointmentTime'
                    } else { valTextLevel = 'arrivalTime' }
                    break;
                case 'TimeRemaining':
                    valTextLevel = 'dueOutTime'
                    break;
                default:
                    valTextLevel = 'name'
                    break;
            }
            switch (sortData[2].value) {
                case 'Room':
                    valTextLevelBy = 'name'
                    break;
                case 'Stage':
                    valTextLevelBy = 'name'
                    break;
                case 'ArrivalTime':
                    valTextLevelBy = 'arrivalTime'
                    break;
                case 'AppointmentTime':
                    valTextLevelBy = 'appointmentTime'
                    break;
                case 'TimeElapsed':
                    if (patientTrackingDoc.visitSettings.countFrom === 'appointmentTime') {
                        valTextLevelBy = 'appointmentTime'
                    } else { valTextLevelBy = 'arrivalTime' }
                    break;
                case 'TimeRemaining':
                    valTextLevelBy = 'dueOutTime'
                    break;
                default:
                    valTextLevelBy = 'name'
                    break;
            }

            sortByVisits = sortWith([
                sortData[0].order === 'DESC' ? descend(prop(`${valText}`)) : ascend(prop(`${valText}`)),
                sortData[1].order === 'DESC' ? descend(prop(`${valTextLevel}`)) : ascend(prop(`${valTextLevel}`)),
                sortData[2].order === 'DESC' ? descend(prop(`${valTextLevelBy}`)) : ascend(prop(`${valTextLevelBy}`)),
            ], nuevofilterArray);

        }
    } else {
        sortByVisits = nuevofilterArray.sort((a, b) => {
            return a.createdTime < b.createdTime;
        });
    }
    // ----------------------------------------------------------
    const sortedVisits = sortByVisits
        .filter(visit => {
            const currentStage = getVisitCurrentStage(visit);

            return currentStage.id === stageId;
        })

    function getVisitCurrentStage(visit) {
        const stageId =
            visit.stageHistory[visit.stageHistory.length - 1].stageId;
        const myStage = getStageById(stageId);
        return myStage;
    }

    function getStageById(stageId) {
        return stages && stages[stageId]
            ? stages[stageId]
            : {
                backgroundColor: "grey",
                id: "zzzzz",
                name: "???",
                textColor: "#000000"
            };
    }

    const myStage = getStageById(stageId);
    return (
        <React.Fragment>
            <div>
                {(localStore.data.showPatientTrackingStageHeaders === 1) && (
                    <div
                        style={{
                            backgroundColor: myStage.backgroundColor,
                            color: myStage.textColor,
                            color: '#fff',
                            fontWeight: "bold",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '4px 20px'
                        }}
                    >

                        <div className={classes.bubbleCustom} style={{ backgroundColor: invertColor(myStage.backgroundColor) }}>
                            {myStage.name}
                            &nbsp;&nbsp;
                            <div> {sortedVisits.length}</div>
                        </div>
                    </div>
                )}
                {(localStore.data.showPatientTrackingStageHeaders === 2) && sortedVisits.length > 0 && (
                    <div
                        style={{
                            backgroundColor: myStage.backgroundColor,
                            color: myStage.textColor,
                            color: '#fff',
                            fontWeight: "bold",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '4px 20px'
                        }}
                    >
                        <div className={classes.bubbleCustom} style={{ backgroundColor: invertColor(myStage.backgroundColor) }}>
                            {myStage.name}
                            &nbsp;&nbsp;
                            <div> {sortedVisits.length}</div>
                        </div>
                    </div>
                )}
                {sortedVisits.map(visit => {
                    return (
                        <VisitContext.Provider key={visit.id} value={visit}>
                            <TableBody />
                        </VisitContext.Provider>
                    );
                })}
            </div>
        </React.Fragment>
    );
}

function invertColor(hex) {

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[2] + hex[2] + hex[2] + hex[2] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 8), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(b) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}