

import React, { useContext, useState, useEffect } from 'react';
import { isEmpty, isNil } from "ramda";
import { format } from "date-fns";

import { Badge, Card, makeStyles, Tooltip, Avatar, ButtonBase } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';

import { compareHash } from "../../Hash";
import GlobalContext from "../../../hooks/GlobalContext";
import MessagingContext from "../Messaging/MessagingContext";
import useMessaging from "../Messaging/useMessaging";
import { countBy } from 'ramda';

import DialogPassword from "../Messaging/DialogPassword";

const useStyles = makeStyles({
    card: {
        backgroundColor: 'white',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
});

export default (props) => {

    const { mainCtx, navigation, pagingDoc } = useContext(
        GlobalContext
    );
    const { usersList, index } = props
    const { messagingDocListener } = useContext(MessagingContext);
    const messaging = useContext(MessagingContext);
    const messagingG = useMessaging();
    // -----------------------------------------------------------
    const classes = useStyles();
    const { localStore } = useContext(GlobalContext);
    const [showFull, setShowFull] = useState(false);
    const [dialogPassword, setDialogPassword] = useState(false);
    // ----------------------------------------------------------------
    const [form, setForm] = useState({ password: '' });
    const [formError, setFormError] = useState({ password: false });
    const [loading, setLoading] = useState(false);
    const [stateVerify, setStateVerify] = useState(false);
    const [toDirect, setToDirect] = useState(false);
    // ----------------------------------------------------------------

    useEffect(() => {
        if(messagingG.stateMessaging){
            // disable sound messaging
            messagingG.setStateMessaging(false);
        }
    }, [messagingG.stateMessaging])

    /**
     * @author Randall Medina
     * @description Method to select an individual existing chat in Messaging/index folder
     * @param user
     */
    const otherConversation = (user) => () => {

        if (user.passwordProtected) {
            setDialogPassword(true);
            setToDirect(false)
        } else {
            messaging.selectUserId(user.id);
            localStore.update(
                localStore.storageKeys.selectedMessagingUserId,
                user.id
            );
            const obj = {
                userId: user.id,
                converId: null,
                userTransmitter: user,
                userReceiver: {},
                groupReceiver: {},
                typeConver: null,
                type: 'other',
            }
            localStore.update(
                localStore.storageKeys.selectedMessagingChat,
                obj
            );
        }
    }

    /**
     * @author Randall Medina
     * @description Method that sends to a direct chat in Messaging/index folder
     * @param user
     */
    const directConversation = (user) => () => {
        if (user.passwordProtected) {
            setDialogPassword(true);
            setToDirect(true);
        } else {

            messaging.selectUserId(user.id);
            messaging.selectConversationId(user.redirectChatId.id);
            if (user.redirectChatId.name || user.redirectChatId.userIds.length > 2) {
                // it's a direct group conversation
                const obj = {
                    userId: user.id,
                    converId: user.redirectChatId.id,
                    userTransmitter: user,
                    userReceiver: {},
                    groupReceiver: user.redirectChatId,
                    typeConver: 2, // 2:Grupal
                    type: 'direct',
                }
                localStore.update(
                    localStore.storageKeys.selectedMessagingChat,
                    obj
                );

            } else {
                // it's a direct individual conversation
                const res = user.redirectChatId.userIds.filter(e => { return e !== user.id });
                if (!isNil(res) && res.length > 0) {

                    const resulPerson = messagingG.users.filter(e => e.id === res[0])
                    const obj = {
                        userId: user.id,
                        converId: user.redirectChatId.id,
                        userTransmitter: user,
                        userReceiver: !isEmpty(resulPerson) ? resulPerson[0] : {},
                        groupReceiver: {},
                        typeConver: 1, // 1:Indivual
                        type: 'direct',
                    }
                    localStore.update(
                        localStore.storageKeys.selectedMessagingChat,
                        obj
                    );
                }
            }
        }
    }
    // -----------------------------------------------------------
    // DialogPassword
    const onChangeText = (event) => setForm({ ...form, [event.target.name]: event.target.value });

    const toggleDialogPass = () => {
        setDialogPassword(!dialogPassword)
        setFormError({ password: false });
        setForm({ password: '' });
        setStateVerify(false)
    };

    /**
    * @author Randall Medina
    * @description Method that the user object receives
    * @param item
    */
    const handleSavePassword = (item) => async () => {
        if (isEmpty(form.password)) {
            setFormError({ password: !form.password });
        } else {
            setLoading(true);
            setFormError({ password: false });

            const resulHash = await compareHash(form.password, item.password)
            if ( resulHash ) {

                if (toDirect) {
                    // when you have 1 notification it is sent to the direct chat
                    messaging.selectUserId(item.id);
                    messaging.selectConversationId(item.redirectChatId.id);

                    if (item.redirectChatId.name || item.redirectChatId.userIds.length > 2) {
                        // it's a direct group conversation
                        const obj = {
                            userId: item.id,
                            converId: item.redirectChatId.id,
                            userTransmitter: item,
                            userReceiver: {},
                            groupReceiver: item.redirectChatId,
                            typeConver: 2, // 2:Grupal
                            type: 'direct',
                        }
                        localStore.update(
                            localStore.storageKeys.selectedMessagingChat,
                            obj
                        );

                    } else {
                        // it's a direct individual conversation
                        const res = item.redirectChatId.userIds.filter(e => { return e !== item.id });
                        if (!isNil(res) && res.length > 0) {

                            const resulPerson = messagingG.users.filter(e => e.id === res[0])
                            const obj = {
                                userId: item.id,
                                converId: item.redirectChatId.id,
                                userTransmitter: item,
                                userReceiver: !isEmpty(resulPerson) ? resulPerson[0] : {},
                                groupReceiver: {},
                                typeConver: 1, // 1:Indivual
                                type: 'direct',
                            }
                            localStore.update(
                                localStore.storageKeys.selectedMessagingChat,
                                obj
                            );
                        }
                    }

                } else {
                    // when it has more than 1 notifications or does not have any, it is sent to the chat list
                    // ------------------------------------------
                    messaging.selectUserId(item.id);
                    localStore.update(
                        localStore.storageKeys.selectedMessagingUserId,
                        item.id
                    );
                    const obj = {
                        userId: item.id,
                        converId: null,
                        userTransmitter: item,
                        userReceiver: {},
                        groupReceiver: {},
                        typeConver: null,
                        type: 'other',
                    }
                    localStore.update(
                        localStore.storageKeys.selectedMessagingChat,
                        obj
                    );
                    // ------------------------------------------
                }

                toggleDialogPass()
                setStateVerify(false)
                setLoading(false);
            } else {
                setStateVerify(true)
                setLoading(false);
            }

        }
    }

    return (
        <>
            <ButtonBase
                key={index}
                onClick={isEmpty(usersList.redirectChatId) ? otherConversation(usersList) : directConversation(usersList)}
                style={{ margin: 7 }}
            >

                {usersList.noti_count > 0 ?
                    <div style={{ background: '#FFF', color: `${usersList.bgColor}`, position: 'absolute', top: 0, zIndex: 1, left: 25, width: 16, height: 16, borderRadius: 16, fontWeight: 700 }}>
                        {usersList.noti_count}
                    </div>
                    : null}

                <Tooltip
                    disabled
                    disableFocusListener
                    key={usersList.id}
                    title={usersList.name}
                >
                    <Avatar
                        style={{
                            backgroundColor: usersList.noti_count > 0 && usersList.bgColor ? `${usersList.bgColor}` : usersList.bgColor ? '#fff' : '#bdbdbd',
                            fontSize: 14,
                            width: 36,
                            height: 36,
                            // margin: 4,
                            fontWeight: 700,
                            color: usersList.noti_count === 0 ? `${usersList.bgColor}` : '#fff',
                            border: usersList.bgColor ? `1px solid ${usersList.bgColor}` : '`1px solid #bdbdbd',
                        }}
                    >
                        {usersList.name.slice(0, 2)}
                    </Avatar>
                </Tooltip>

            </ButtonBase>
            {/* ------------------------------------ */}
            {dialogPassword && (
                <DialogPassword
                    visible={dialogPassword}
                    toggle={toggleDialogPass}
                    handleSavePassword={handleSavePassword}
                    onChangeText={onChangeText}
                    person={usersList}
                    form={form}
                    formError={formError}
                    loading={loading}
                    stateVerify={stateVerify}
                />
            )}
            {/* ------------------------------------ */}
        </>
    )
}