import React, { useState, useEffect, useContext, useRef } from "react";

import GlobalContext from "../../../hooks/GlobalContext";
import { useDocListener } from "./docListener";
import { useQueryListener } from "./queryListener";

import { playSoundEffect } from "../../../audio/audio";
import soundEffects from "../../../audio/soundEffects";
import { isEmpty, isNil } from "ramda";

let firstConversations = [];
let firstConversationsFilled = false;

export default function (props) {
    const { mainCtx, localStore, pagingDoc, licensesDoc, firebase } = useContext(GlobalContext);
    const { officeId } = mainCtx;

    const [stateMessaging, setStateMessaging] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedConversationId, setSelectedConversationId] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const lastAlertTime = useRef(firebase.currentTime);

    let users = [];
    let usersNewPaging = [];
    let conversations = [];
    let conversationsFull = [];

    const messagingDocListener = useDocListener();
    const conversationQueryListener = useQueryListener();

    if (messagingDocListener.data && messagingDocListener.data.users) {
        const persons = pagingDoc.persons;

        const resul = ListifyUsers(messagingDocListener.data.users);
        const resulEnd = resul.map(e => {
            return { ...e, bgColor: e.bgColor ? e.bgColor : `${Object.keys(persons).filter(j => persons[j].name === e.name).map((resp) => persons[resp].bgColor)}` }
        });
        let dataFilterPaging = resulEnd.filter(e  => e.type === 'ambos'||  e.type === 'paging' );
        usersNewPaging = usersNewPaging.concat(dataFilterPaging)
        let dataFilterMessaging = resulEnd.filter(e => e.type === 'ambos'||  e.type === 'messaging' );
        users = users.concat(dataFilterMessaging)
    }

    if (conversationQueryListener.data) {
        // *********** conversations reply by user id *********** //
        conversations = conversationQueryListener.data;
    }
    if (conversationQueryListener.dataFull) {
        conversationsFull = conversationQueryListener.dataFull;

        if (!firstConversationsFilled && conversationQueryListener.requestDataFullCompleted) {
            firstConversationsFilled = true;
            firstConversations = conversationsFull.map(item => item.latestMessage && item.latestMessage.id).filter(item => item)
        }
    }

    window.getChat = function () {
        console.log(firstConversations.length)
    }

    useEffect(() => {
        // variable that is filled when a conversation is selected from home
        if ((localStore.data.hasOwnProperty('selectedMessagingChat') && !isEmpty(localStore.data.selectedMessagingChat)) && selectedUserId) {

            selectUserIdTwo(localStore.data.selectedMessagingChat.userId)
            selectConversationIdTwo(localStore.data.selectedMessagingChat.converId)
        }
    }, [localStore.data.selectedMessagingChat, selectedUserId]);

    useEffect(() => {

        selectUserId(null);
        if (!officeId) {
            messagingDocListener.unsubscribe();
            return;
        }
        // *********** executed at startup *********** //
        messagingDocListener.subscribe(`/offices/${officeId}/app/messaging/`);

    }, [officeId]);


    useEffect(() => {

        // ----------------------------- 
        // *********** when the application starts, load all messages ***********  //
        conversationQueryListener.subscribeTest(`/offices/${officeId}/app/messaging/conversations/`);

        if (!selectedUserId) {
            conversationQueryListener.unsubscribe();
            return;
        }
        // *********** run when selecting a user *********** //
        conversationQueryListener.subscribe(
            `/offices/${officeId}/app/messaging/conversations`,
            [["where", "userIds", "array-contains", selectedUserId]]
        );
    }, [selectedUserId]);

    //load last user on start
    useEffect(() => {
        if (initializing && users.length > 0) {
            const storedMessagingUserId =
                localStore.data.selectedMessagingUserId;
            if (!storedMessagingUserId) {
                return;
            }
            const userExists = users.some((user) => {
                return user.id === storedMessagingUserId;
            });
            if (userExists) {
                selectUserId(storedMessagingUserId);
            }
            setInitializing(false);
        }
    }, [messagingDocListener.data]);

    // -----------------------------------------------------------------------------
    // Suena todos sin excepcion
    // useEffect(() => {
    //     conversationsFull.forEach((conv) => {
    //         const latestMessage = conv.latestMessage;
    //         const isNew = latestMessage.time > lastAlertTime.current;
    //         const isByMe =
    //             conv.participants[selectedUserId] && conv.participants[selectedUserId].lastMessage &&
    //             conv.participants[selectedUserId].lastMessage.id ===
    //             latestMessage.id;

    //         if (!isByMe && isNew) {
    //             lastAlertTime.current = latestMessage.time;
    //             if (localStore.data.messagingSoundEnabled) {
    //                 playSoundEffect(soundEffects["s2"]);
    //             }
    //         }
    //     });
    // }, [users, conversationsFull]);

    // -----------------------------------------------------------------------------
    // Suena todos con excepcion selectedPeopleKeep
    useEffect(() => {

        function fetch() {

            if (Object.keys(licensesDoc.licenses).length > 0) {
                const licenseMap = Object.keys(licensesDoc.licenses).map(t => ({ ...licensesDoc.licenses[t], licenseId: t }));
                const resul = licenseMap.find(t => t.licenseId === localStore.data.messageLicense);
                if (!isNil(resul) && resul.hasOwnProperty('selectedPeopleKeep') && resul.selectedPeopleKeep.length > 0) {

                    const resultUser = resul.selectedPeopleKeep.map(e => {
                        const tt = conversationsFull.length > 0 ? conversationsFull.map((resp) => {
                            return { ...resp, resul: resp.userIds.filter(ids => ids === e.id) }
                        }) : [];
                        return { ...e, canales: tt.length > 0 ? tt.filter(j => j.resul[0] === e.id) : [] }
                    });

                    resultUser.map((user) => {
                        user.canales
                            // .sort((a, b) => {
                            //     const atime = parseInt(a.latestMessage.time);
                            //     const btime = parseInt(b.latestMessage.time);
                            //     return btime - atime;
                            // })
                            .map((item, index) => {
                                const latestMessage = item.latestMessage;
                                const isNew = firstConversationsFilled ? firstConversations.find(_item => _item === latestMessage.id) ? false : true : false;

                                const isByMe =
                                    item.participants[user.id] && item.participants[user.id].lastMessage &&
                                    item.participants[user.id].lastMessage.id === latestMessage.id;

                                if (!isByMe && isNew && stateMessaging) {
                                    lastAlertTime.current = latestMessage.time;
                                    firstConversations.push(latestMessage.id);
                                    console.log('===== Wow! sounded good =====')
                                    playSoundEffect(soundEffects["s6"]);
                                    
                                }
                            });
                    });

                } else {
                    console.log('===== ¡no people selected! =====')
                }
            }
        }
        fetch();

    }, [licensesDoc.licenses && Object.keys(licensesDoc.licenses).length > 0, conversationsFull])
    // -----------------------------------------------------------------------------

    // *********** load the selected user to view their conversations *********** //
    function selectUserId(id) {
        if (id !== selectedUserId) {
            conversationQueryListener.unsubscribe();
            setSelectedConversationId(null);
            setSelectedUserId(id);
        }
    }
    function selectUserIdTwo(id) {
        setSelectedUserId(id);
    }

    // *********** load the selected user's conversations *********** //
    function selectConversationId(id) {
        setSelectedConversationId(id);
    }
    function selectConversationIdTwo(id) {
        setSelectedConversationId(id);
    }
    // *********** Gets the name of the person who is going to write *********** //
    function getUserById(id) {
        const matches = users.filter((user) => {
            return user.id === id;
        });
        if (matches.length > 0) {
            return matches[0];
        } else {
            return null;
        }
    }

    // *********** load conversations from a selected chat *********** //
    function getConversationById(id) {
        const matches = conversations.filter((conversation) => {
            return conversation.id === id;
        });
        if (matches.length > 0) {
            return matches[0];
        } else {
            return null;
        }
    }

    return {
        users,
        conversations,
        conversationsFull,
        selectedUserId,
        selectUserId,
        selectedConversationId,
        selectConversationId,
        getUserById,
        getConversationById,
        messagingDocListener,
        conversationQueryListener,
        stateMessaging, 
        setStateMessaging,
        usersNewPaging
    };
}

function ListifyUsers(usersObj) {
    return Object.keys(usersObj)
        .map((userId) => {
            return usersObj[userId];
        })
        .sort((a, b) => {
            if (!a.name || b.name) {
                return -1;
            }
            return a.name.localeCompare(b.name);
        });
}
