import { createContext, useState, useEffect } from "react";
import * as firebase from "firebase";

export const serverContext = createContext({
  currentTime: null,
});

export default function () {
  const [currentTime, setCurrentTime] = useState(null);

  async function getCurrentTime() {
    const data = await firebase
      .database()
      .ref("/.info/serverTimeOffset")
      .once("value");

    setCurrentTime(data.val() + Date.now());
  }

  /*eslint-disable*/
  useEffect(() => {
    getCurrentTime()
  }, []);

  return {
    currentTime,
    setCurrentTime,
  };
}
