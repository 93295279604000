import React, { useEffect, useState, useContext } from 'react';
import { Resizable } from "re-resizable";
import { isEmpty } from 'ramda';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Paper, Table } from '@material-ui/core';

import GlobalContext from "../../../hooks/GlobalContext";
import PatientTrackingContext from "./PatientTrackingContext";
import TickerContext from "./TickerContext";
import usePatientTracking from "./usePatientTracking";
import useTicker from "./useTicker";

import TopBar from "./TopBar";
import DialogVisit from "./DialogVisit";

// -----------------------------------------------------------
import TableHeader from "./Table/TableHeader";
import ViewDefault from "./Views/Default";
// -----------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: '5%'
        // backgroundColor: theme.palette.background.paper,
        // padding: '1em 0 3em'
    },
    scrollCustom: {

        background: '#fff',
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
            backgroundColor: '#fff',
            width: 12,     /* Tamaño del scroll en vertical */
            height: 12,    /* Tamaño del scroll en horizontal */
            display: 'none',  /* Ocultar scroll */
        },

        /* background of the scrollbar except button or resizer */
        '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

        '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

        /* scrollbar itself */
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#babac0',
            borderRadius: 16,
            border: '4px solid #fff',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a0a0a5',
            border: '4px solid #f4f4f4'
        },

        /* set button(top and bottom of the scrollbar) */
        '&::-webkit-scrollbar-button': { display: 'none' },
    }
}));

export default function (props) {

    const { mainCtx, localStore } = useContext(GlobalContext);
    const { ticks } = useTicker();
    const patientTracking = usePatientTracking();
    const { stages, visits } = patientTracking;

    const classes = useStyles();
    const [dialogVisit, setDialogVisit] = useState(false)
    // ----------------------------------------------------------
    const smScreen = useMediaQuery("(max-width:600px)");
    const mdScreen = useMediaQuery("(max-width:960px)");
    const lgScreen = useMediaQuery('(max-width:1280px)');
    const xlScreen = useMediaQuery("(max-width:1920px)");
    // ----------------------------------------------------------
    const toggleVisit = () => setDialogVisit(!dialogVisit);
    // ----------------------------------------------------------

    return (
        <React.Fragment>

            <PatientTrackingContext.Provider value={patientTracking}>
                <div className={classes.root}>
                    <TickerContext.Provider value={ticks}>
                       
                        <TopBar toggleVisit={toggleVisit} />
                        
                        <TableHeader />
                        
                        <Resizable
                            style={{ overflowY: "scroll", overflowX: "hidden", border: '2px solid #197fe8', borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }}
                            defaultSize={{ height: `${localStore.data.patientTrackingHeight}px` }}
                            enable={{ bottom: true, top: true }}
                            size={{ height: `${localStore.data.patientTrackingHeight}px` }}
                            onResizeStop={(event, direction, refToElement, delta) => {
                                const newHeight = parseInt(refToElement.style.height);
                                localStore.update(localStore.storageKeys.patientTrackingHeight, newHeight);
                            }}
                            className={classes.scrollCustom}
                        >
                            {Object.keys(stages)
                                .sort((idA, idB) => {
                                    const stageA = stages[idA];
                                    const stageB = stages[idB];

                                    return stageA.name.localeCompare(
                                        stageB.name
                                    );
                                })
                                .map((stageId, index) => {
                                    return (
                                        <ViewDefault
                                            key={stageId}
                                            stageId={stageId}
                                            index={index}
                                        />
                                    );
                                })}
                        </Resizable>
                        
                        {dialogVisit && (
                            <DialogVisit
                                visible={dialogVisit}
                                toggle={toggleVisit}
                            />
                        )}
                       
                    </TickerContext.Provider>
                </div>
            </PatientTrackingContext.Provider>

        </React.Fragment>
    );
}