import React, { useEffect } from 'react';
import moment from "moment";
// import { isEmpty, isNil, view } from 'ramda';
// import { format, parseISO } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, MenuList, Paper, Grow, Popper, ClickAwayListener, Tooltip } from "@material-ui/core";

import RedoIcon from '@material-ui/icons/Redo';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import trackingEdit from '../../../../assets/trackingEdit.png';
import trackingEnd from '../../../../assets/trackingEnd.png';
import trackingNext from '../../../../assets/trackingNext.png';
import { isEmpty } from 'ramda';

const useStyles = makeStyles({
    root: {
        width: '96.6%',
        top: '-39.7px !important',
        left: '2px !important',
    },
    grow: {
       backgroundColor: '#000000b0',
       borderRadius: 0
    },
    menuList: {
        display: 'flex',
        padding: 0,
        height: 40,
        justifyContent: 'flex-end'
    },
    menuItem: {
        minHeight: 20,
        width: 34,
        display: 'flex',
        justifyContent: 'center',

        '& svg': {
            fontSize: 18,
            color: '#fff'
        },
        '& img': {
            width: 15,
            height: 'auto'
        }
    },
});

export default function (props) {
    const { handleClose, placement, anchorEl, open, visit } = props;
    // -------------------------------------------------------
    const classes = useStyles();

    // ----------------------------------------------------------
    useEffect(() => {
        if (open) {
            document.body.style = "overflow-x: hidden;";
        } else {
            document.body.style = "";
        }
    }, [open])

    return (

        <Popper open={open} anchorEl={anchorEl} transition className={classes.root}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center center' : 'center center' }}
                    className={classes.grow}
                >
                    <Paper id="menu-list-grow">
                        <ClickAwayListener onClickAway={handleClose('')}>
                            <MenuList className={classes.menuList}>
                                <Tooltip title={'Next State'}>
                                    <MenuItem onClick={handleClose('next')} className={classes.menuItem}>
                                        {/* <RedoIcon /> */}
                                        <img src={trackingNext} />
                                    </MenuItem>
                                </Tooltip>
                                <Tooltip title={'Edit'}>
                                    <MenuItem onClick={handleClose('edit')} className={classes.menuItem}>
                                        {/* <BorderColorOutlinedIcon /> */}
                                        <img src={trackingEdit} />
                                    </MenuItem>
                                </Tooltip>
                                <Tooltip title={'End Visit'}>
                                    <MenuItem onClick={handleClose('Finalize')} className={classes.menuItem}>
                                        {/* <ExitToAppIcon /> */}
                                        <img src={trackingEnd} />
                                    </MenuItem>
                                </Tooltip>
                                {visit.hasOwnProperty('contactHistory') && !isEmpty(visit.contactHistory) && visit.contactHistory.favorite && visit.contactHistory.favorite === 'email' ||
                                visit.hasOwnProperty('contactHistory') && !isEmpty(visit.contactHistory) && !visit.contactHistory.hasOwnProperty('favorite') ||
                                visit.hasOwnProperty('contactHistory') && !isEmpty(visit.contactHistory) && visit.contactHistory.favorite === '' ?
                                    <Tooltip title={'Send Mail'}>
                                        <MenuItem onClick={handleClose('email')} className={classes.menuItem}>
                                            <EmailOutlinedIcon/>
                                        </MenuItem>
                                    </Tooltip>
                                : null}
                                {visit.hasOwnProperty('contactHistory') && !isEmpty(visit.contactHistory) && visit.contactHistory.favorite && visit.contactHistory.favorite === 'phone' ||
                                visit.hasOwnProperty('contactHistory') && !isEmpty(visit.contactHistory) && !visit.contactHistory.hasOwnProperty('favorite') ||
                                visit.hasOwnProperty('contactHistory') && !isEmpty(visit.contactHistory) && visit.contactHistory.favorite === '' ?
                                    <Tooltip title={'Send SMS'}>
                                        <MenuItem onClick={handleClose('sms')} className={classes.menuItem}>
                                            <MessageOutlinedIcon />
                                        </MenuItem>
                                    </Tooltip>
                                : null}
                                <Tooltip title={'Close'}>
                                    <MenuItem onClick={handleClose('close')} className={classes.menuItem}>
                                        <CloseOutlinedIcon />
                                    </MenuItem>
                                </Tooltip>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>

    );
}
