import React, { useContext, useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import { isEmpty } from 'ramda';

import { Avatar, Tooltip, Card, useMediaQuery } from "@material-ui/core";

import MessagingContext from "../MessagingContext";

export default function (props) {
    const { participantIds } = props;
    const messaging = useContext(MessagingContext);
    // ---------------------------------------------------------------------
    // const theme = useTheme();
    // const matchesXs = useMediaQuery(theme.breakpoints.only('xs'));
    // ---------------------------------------------------------------------
    const smScreen = useMediaQuery("(max-width:600px)");
    const mdScreen = useMediaQuery("(max-width:960px)");
    const lgScreen = useMediaQuery('(max-width:1280px)');
    const xlScreen = useMediaQuery("(max-width:1920px)");
    // ---------------------------------------------------------------------
    const [tooltipListPerson, setTooltipListPerson] = useState([]);
    const [newArrayPerson, setNewArrayPerson] = useState([]);
    const [remainingPerson, setRemainingPerson] = useState([]);
    const [morePerson, setMorePerson] = useState(false);
    // ---------------------------------------------------------------------

    useEffect(() => {
        if (!isEmpty(participantIds)) {

            // si hay un grupo de persona mayor o igual 3
            if (participantIds.length >= 3) {
                // --------------------------------------
                let responsive = smScreen ? 9 : mdScreen ? 15 : lgScreen ? 20 : xlScreen ? 27 : participantIds.length;
                // --------------------------------------
                setNewArrayPerson(participantIds.slice(0, responsive))
                const restant = participantIds.slice(responsive, participantIds.length);
                setRemainingPerson(restant)
                // --------------------------------------
                const res = restant.map((userId, ind) => {
                    const user = messaging.getUserById(userId);
                    return <div> {user && user.name ? user.name : userId} <br /></div>;
                });
                setTooltipListPerson(res)
                // --------------------------------------
                if (restant.length > 0) {
                    setMorePerson(true)
                } else {
                    setMorePerson(false)
                }
            } else {
                // si hay un grupo de persona menor 3
                setNewArrayPerson(participantIds)
            }
        }
    }, [participantIds, smScreen, mdScreen, lgScreen, xlScreen]);

    return (

        <div style={{ display: 'flex', width: '70%' }}>
            {newArrayPerson.map((userId, ind) => {
                const user = messaging.getUserById(userId);
                const name = user && user.name ? user.name : userId;
                return (
                    <div
                        style={{
                            color: '#3A3E4B',
                            fontSize: 15,
                            fontWeight: 700,
                        }}
                        key={user && user.id ? user.id : userId}
                    >
                        <Tooltip
                            disabled
                            disableFocusListener
                            key={user && user.id ? user.id : userId}
                            title={name}
                        >
                            <Avatar
                                style={{
                                    backgroundColor: user && user.bgColor ? user.bgColor : '#bdbdbd',
                                    textAlign: "center",
                                    fontSize: "10px",
                                    width: "26px",
                                    height: "26px",
                                    display: "inline-flex",
                                    marginRight: 5
                                }}
                            >
                                {name.slice(0, 2)}
                            </Avatar>
                        </Tooltip>
                    </div>
                );
            })}

            {/* circulo de cantidad de persona restante */}
            {morePerson ?

                <Tooltip
                    disabled
                    disableFocusListener
                    title={tooltipListPerson}
                >
                    <div style={{
                        backgroundColor: "#EEF1F8",
                        fontSize: 12,
                        fontWeight: '600',
                        display: "flex",
                        margin: 0,
                        padding: '0 20px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#696F84'
                    }}>
                        +{remainingPerson.length}
                    </div>
                </Tooltip>
                : null
            }
        </div >
    );
}
