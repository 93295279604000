import React, { useEffect, useState, useRef, useContext } from "react";
import { isEmpty, isNil } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

import pagePersonToRoom from "../../../../../firebase/httpsCallable/pagePersonToRoom";
import pageRoomToPerson from "../../../../../firebase/httpsCallable/pageRoomToPerson";
import RoomContext from "./RoomContext";
import useMessaging from "../../../Messaging/useMessaging";
import PersonsContainer from "./PersonsContainer";
import { ButtonBase, Tooltip, Paper, Button } from "@material-ui/core";
import { useTimer } from "./TimerContext";
import GlobalContext from "../../../../../hooks/GlobalContext";
import { playSoundEffect } from "../../../../../audio/audio";
import soundEffects from "../../../../../audio/soundEffects";

const useStyles = makeStyles({
    boxButton: {
        color: 'white',
        height: 48,
        '& p': {
            margin: 0,
            padding: 0,
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textTransform: 'none',
    },
    boxPaper:{
        
        backgroundColor: '#9cc6f3', 
        color: "white",
        minWidth: "0",
        overflow: "hidden",
        borderRadius: 10,
        display: "grid",
        gridTemplateRows: `minmax(0px, 1fr) minmax(0px,2fr)`,
        textAlign: "center",
        margin: 5,
        height: 50,
        
        '&:hover ':{
            backgroundColor: "#197FE8",
        }

    }
});

export default function(props) {
    const { mainCtx, pagingDoc, pagingState, localStore } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    // const { persons: allPersons } = pagingDoc;
    const usersData = useMessaging();
    const allPersons = usersData.usersNewPaging;

    const {
        selectedPagingPersonId,
        setSelectedPagingPersonId,
        setSelectedMeaningButtonId,
        selectedMeaningButtonId
    } = pagingState;
    const { pause, unpause, onTick } = useTimer();
    const room = props.room;
    const persons = room.persons;
    const displayedPersonIndexRef = useRef(0);
    const classes = useStyles();
    let actionRef = useRef();

    const [displayedPersonIndex, setDisplayedPersonIndex] = useState(0);

    const setIndex = index => {
        displayedPersonIndexRef.current = index;
        setDisplayedPersonIndex(index);
    };
    useEffect(() => {
        onTick(() => {
            setIndex(displayedPersonIndexRef.current + 1);
        });

        return () => {
            onTick(() => {});
        };
    }, []);

    const displayedPersonId = Object.keys(persons)[
        displayedPersonIndexRef.current % Object.keys(persons).length
    ];

    const onClickRoom = (e) => {
        if( Object.keys(persons).length === 6){
            if(actionRef.current){
                actionRef.current.setAttribute("disabled", "disabled");
            }
        }else{
            if (selectedPagingPersonId) {
                setSelectedMeaningButtonId(null);
                // --------------------------------------------------------------------------
                // pagePersonToRoom({
                pageRoomToPerson({
                   officeId,
                   personId: selectedPagingPersonId,
                   meaningButtonId: selectedMeaningButtonId,
                   roomId: room.roomId
                }).then((resul) => {
                    // const soundId = resul.data && resul.data.soundId ?  resul.data.soundId : 's1';
                    // if (localStore.data.pagingSoundEnabled) {
                    //     playSoundEffect(soundEffects[soundId]);
                    // }
                    setSelectedPagingPersonId(null);
                    props.closePaging()
                })
                .catch(err => {
                    console.error(err);
                });
                // --------------------------------------------------------------------------
               
            }
        }
    }

    const handleRoomContainedPeople = (item) => {

        let newData = [];
        Object.keys(allPersons).map( t => {
            if( !isNil(allPersons[t].rooms) && !isEmpty(allPersons[t].rooms) ){
                const resul = Object.keys(allPersons[t].rooms).filter(id => id === item.roomId);
                if( !isNil(resul) ){
                    newData = newData.concat(resul)
                }
            }
        })
        return newData.length
    }

    return (
        <RoomContext.Provider
            value={{
                room,
                persons,
                displayedPersonId,
                setIndex
            }}
        >
            <Tooltip
                title={
                    Object.keys(persons).length  < 6 && selectedPagingPersonId && !isEmpty(room) &&  allPersons[selectedPagingPersonId]
                        ? `${allPersons[selectedPagingPersonId].name} to ${room.name}`
                        :  Object.keys(persons).length === 6 ? `exceeded the maximum number of people` : ""
                }
                enterTouchDelay={200}
            >
                <Paper
                    ref={actionRef} 
                    className={classes.boxPaper}
                    onMouseEnter={() => {
                        pause();
                    }}
                    onMouseLeave={() => {
                        unpause();
                    }}
                    onClick={onClickRoom}
                    // onClick={e => {
                    //     if (selectedPagingPersonId) {
                    //         setSelectedMeaningButtonId(null);
                    //         pagePersonToRoom({
                    //             officeId,
                    //             personId: selectedPagingPersonId,
                    //             meaningButtonId: selectedMeaningButtonId,
                    //             roomId: room.roomId
                    //         });
                    //         setSelectedPagingPersonId(null);
                    //         props.closePaging()
                    //     }
                    // }}
                >
                    <Button className={classes.boxButton}>
                        <p style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: 14
                        }}>{room.name}</p>
                        <p style={{
                            fontSize: 10
                        // }}>{Object.keys(persons).length} people</p>
                        }}>{handleRoomContainedPeople(room) || 0} people</p>
                    </Button>
                </Paper>
            </Tooltip>
        </RoomContext.Provider>
    );
}
