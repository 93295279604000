import React, { useContext, useState } from "react";
import {
    Grid,
    TextField,
    Checkbox,
    MenuItem,
    FormControlLabel,
    Slider
} from "@material-ui/core";

import GlobalContext from "../../hooks/GlobalContext";
export default React.forwardRef((props, ref) => {
    const { localStore } = useContext(GlobalContext);
    const { volumeControl } = useContext(GlobalContext);
    const [tempVolume, setTempVolume] = useState(volumeControl.volume);
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <React.Fragment>
            <MenuItem>
                <Slider
                    style={{ minHeight: "10vh" }}
                    orientation="vertical"
                    defaultValue={volumeControl.volume}
                    aria-labelledby="volume-slider"
                    valueLabelDisplay="auto"
                    value={tempVolume}
                    onChange={(e, v) => {
                        setTempVolume(v);
                    }}
                    step={1}
                    max={100}
                    min={0}
                    onChangeCommitted={(e, v) => {
                        volumeControl.updateVolume(v);
                        setAnchorEl(null);
                        volumeControl.testVolume();
                    }}
                />
            </MenuItem>
            <MenuItem>
                <FormControlLabel
                label={"Paging"}
                    control={
                        <Checkbox
                            checked={localStore.data.pagingSoundEnabled}
                            onChange={e => {
                                localStore.update(
                                    localStore.storageKeys.pagingSoundEnabled,
                                    e.target.checked
                                );
                            }}
                        ></Checkbox>
                    }
                ></FormControlLabel>
            </MenuItem>
            <MenuItem>
                <FormControlLabel
                label={"Messaging"}
                    control={
                        <Checkbox
                            checked={localStore.data.messagingSoundEnabled}
                            onChange={e => {
                                localStore.update(
                                    localStore.storageKeys.messagingSoundEnabled,
                                    e.target.checked
                                );
                            }}
                        ></Checkbox>
                    }
                ></FormControlLabel>
            </MenuItem>
            <MenuItem>
                <FormControlLabel
                    label={"Patient Tracking"}
                    control={
                        <Checkbox
                            checked={
                                localStore.data.patientTrackingSoundEnabled
                            }
                            onChange={e => {
                                localStore.update(
                                    localStore.storageKeys
                                        .patientTrackingSoundEnabled,
                                    e.target.checked
                                );
                            }}
                        ></Checkbox>
                    }
                ></FormControlLabel>
            </MenuItem>
        </React.Fragment>
    );
});
